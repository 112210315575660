import React from 'react';
import {Card, WingBlank, WhiteSpace, Icon, NavBar, Carousel, NoticeBar, Tabs, List} from 'antd-mobile';
import {Alert, Badge, Button, Form, InputNumber, Modal, notification} from "antd";
import {UserCharts} from "../UserCharts/UserCharts";
import {CalendarOutlined, CalendarTwoTone} from "@ant-design/icons";
import moment from "moment";
import instance from "../../../../../Utils/API";


export const MobileEnumerators = props => {

    const [visible, setVisible] = React.useState(false);
    const [enumData, setEnumData] = React.useState('');
    const Item = List.Item;
    const tabs = [
        { title: "Платежи" },
        { title: "Показания" }

    ];

    const onFinish = (values) => {
        instance.put('/api/enumerator/updateValue', {id: enumData.id, value: values.Value})
            .then(function (response) {
                props.getUserData();
                notification['success']({
                    message: 'Значение счётчика было успешно изменено!',
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    };
    return(
        <div>
            <NavBar
                mode="dark"
                rightContent={[
                    <span>
                        Баланс: {props.userEnumerators.balance.balance} ₽
                    </span>
                ]}
            >
                Счетчики
            </NavBar>
            <WingBlank size="lg">
                {new Date().getDate() > 25 &&
                    <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                        Внимание! Внесенные показания с 25 числа по последнее число месяца сохраняться не будут!
                    </NoticeBar>
                }
                {
                    props.userEnumerators.enumeratorList.map((item) => (
                        <div>
                            <WhiteSpace size="lg" />
                            <Card onClick={() => {setVisible(true); setEnumData(item)}}>
                                <Card.Header
                                    title={item.enumeratorType === 'GVS' ? "ГВС" : "Отопление"}
                                    thumb={<CalendarTwoTone twoToneColor="#c75050" style={{marginRight: '5px'}}/>}
                                    extra={<span>{item.enumeratorMark} {item.enumeratorSerialNumber}</span>}
                                />
                                <Card.Body>
                                    <div><b>{item.enumeratorType === 'GVS' ? "ГВС" : "Отопление"} | {item.enumeratorMark} {item.enumeratorSerialNumber}</b></div>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px'}}>
                                        <span>Последнее показание:</span>
                                        <span>{item.enumeratorValue}</span>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'}}>
                                        <span>Дата последнего показания:</span>
                                        <span>{moment(item.lastTimeUpdated).format('DD.MM.YYYY')}</span>
                                    </div>
                                </Card.Body>
                                <Card.Footer content="Дата показания" extra={<div>{moment(item.lastTimeUpdated).format('DD.MM.YYYY')}</div>} />
                            </Card>
                            <WhiteSpace size="lg" />
                        </div>
                    ))
                }

                <Tabs tabs={tabs}
                      initialPage={0}
                >
                    <div style={{backgroundColor: '#fff',minHeight: '150px' }}>
                        <List renderHeader={() => 'Список платежей'} className="my-list">
                            {
                                props.userEnumerators.userPaymentList.map((item) => (
                                    <Item extra={[
                                        <Badge status={item.paymentStatus === true ? 'success' : 'error'} />,
                                        moment(item.paymentTime).format('DD.MM.YYYY'),
                                    ]}>
                                        {item.paymentAmount} ₽
                                    </Item>
                                ))
                            }

                        </List>
                    </div>
                    <div style={{backgroundColor: '#fff',minHeight: '150px' }}>
                        <List renderHeader={() => 'Список показаний'} className="my-list">
                            {
                                props.userEnumerators.enumeratorValueHistoryDataList.map((item) => (
                                    <Item extra={[
                                        moment(item.changeTime).format('DD.MM.YYYY'),
                                    ]}>
                                        {item.value}
                                    </Item>
                                ))
                            }

                        </List>
                    </div>
                </Tabs>
                <WhiteSpace />
            </WingBlank>

            <Modal
                title={enumData.enumeratorType === 'GVS' ? 'Горячее водоснабжение' : 'Отопление'}
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                footer={false}
            >
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    layout={"vertical"}
                    onFinish={onFinish}
                    fields={[
                        {
                            name: 'Value',
                            value: enumData.enumeratorValue
                        }
                    ]}
                >
                    <Alert message="Дробные значения записываются через точку" type="info" showIcon style={{marginBottom: 20}}/>
                    <Form.Item
                        label="Новое показание"
                        name="Value"
                        layout={"inline"}
                        rules={[{ required: true, message: 'Пожалуйста, введите нововое показание!' }]}
                    >
                        <InputNumber
                            formatter={value => value.replace(',','.')}
                            parser={value => value.replace(',','.')}
                            min={enumData.enumeratorValue}
                            style={{width: '100%'}}
                            placeholder={enumData.enumeratorValue}
                        />
                    </Form.Item>

                    <Form.Item style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{display: 'flex', justifyContent: 'flex-end'}}
                        >
                            Внести
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}