import React from "react";
import {List, NavBar} from "antd-mobile";
import { useHistory } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit'
import { useAuthUser } from 'react-auth-kit'
import {useIsAuthenticated} from 'react-auth-kit';
import {Menu} from 'antd';


export const MobileMenu = props => {
    const history = useHistory();
    const signOut = useSignOut();
    const auth = useAuthUser();
    const isAuth = useIsAuthenticated()
    const Item = List.Item;

    return(
        <div>
            <NavBar
                mode="dark"
            >
                Меню
            </NavBar>
            <List renderHeader={() => 'Страницы сайта'}>
                <Item
                    thumb={<img src={process.env.PUBLIC_URL + '/img/aboutCompany.svg'} alt="О компании"/>}
                    arrow="horizontal"
                    onClick={() => {
                        isAuth() ? history.push('/company/about') : history.push('/')
                    }}
                >
                    О компании
                </Item>
                <Item
                    thumb={<img src={process.env.PUBLIC_URL + '/img/gas.svg'} alt="Сжиженный газ"/>}
                    onClick={() => {
                        isAuth() ? history.push('/company/gas') : history.push('/')
                    }}
                    arrow="horizontal"
                >
                    Сжиженный газ
                </Item>
                <Item
                    thumb={<img src={process.env.PUBLIC_URL + '/img/services.svg'} alt="Услуги"/>}
                    onClick={() => {
                        isAuth() ? history.push('/company/services') : history.push('/')
                    }}
                    arrow="horizontal"
                >
                    Услуги
                </Item>
                <Item
                    thumb={<img src={process.env.PUBLIC_URL + '/img/contacts.svg'} alt="Контакты"/>}
                    onClick={() => {
                        isAuth() ? history.push('/company/contacts') : history.push('/')
                    }}
                    arrow="horizontal"
                >
                    Контакты
                </Item>
                <Item
                    thumb={<img src={process.env.PUBLIC_URL + '/img/newspaper.svg'} alt="Новости"/>}
                    onClick={() => {
                        isAuth() ? history.push('/company/news') : history.push('/')
                    }}
                    arrow="horizontal"
                >
                    Новости
                </Item>
                <Item
                    thumb={<img src={process.env.PUBLIC_URL + '/img/report.svg'} alt="Отчетность"/>}
                    onClick={() => window.location.assign('http://www.disclosure.ru/issuer/8601014059/')}
                    arrow="horizontal"
                >
                    Отчетность
                </Item>
                <Item
                  arrow="horizontal"
                  onClick={() => {
                      isAuth() ? history.push('/company/formgasification') : history.push('/')
                  }}>
                    Газификация
                </Item>
                <Item
                    thumb={<img src={process.env.PUBLIC_URL + '/img/information.svg'} alt="Раскрытие информации"/>}
                    onClick={() => {
                        isAuth() ? history.push('/company/secret') : history.push('/')
                    }}
                    arrow="horizontal"
                >
                    Раскрытие информации
                </Item>
                <Item
                    style={{backgroundColor: '#e22929', marginTop: 30}}
                    thumb={<img src={process.env.PUBLIC_URL + '/img/exit.svg'} alt="Выход"/>}
                    onClick={() => {signOut(); window.location.reload()}}
                    arrow="horizontal"
                >
                    Выход
                </Item>
            </List>
        </div>
    )
}
