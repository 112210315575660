import React from 'react';
import {
    Button,
    Table,
    Drawer,
    Form,
    Input,
    InputNumber,
    Pagination,
    Tag,
    notification,
    Col,
    Card,
    Row,
    Statistic, Empty, Modal
} from 'antd';
import {useHistory} from 'react-router-dom';
import moment from "moment";
import instance from "../../../../../Utils/API";
import EnterEvalueModal from "../../UserProfile/Enumerators/EnterEvalueModal/EnterEvalueModal";


export const TablePayments = props => {
    const history = useHistory();
    const columns = [
        {
            title: 'Номер договора',
            dataIndex: 'contractNumber',
            key: '1',
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: '2',
            render: (item) => {
                return(
                    <span>{item} ₽</span>
                )
            }
        }
        ,
        {
            title: 'ID платежи Сбербанка',
            dataIndex: 'bankPaymentId',
            key: '3',
        },
        {
            title: 'ID платежа сайта',
            dataIndex: 'vendorPaymentId',
            key: '4',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: '5',
            render: (item) => {
                if(item === true){
                    return(
                        <Tag color="green">Оплачен</Tag>
                    )
                }else{
                    return(
                        <Tag color="red">Не оплачен</Tag>
                    )
                }
            }
        },
        {
            title: 'Дата платежа',
            dataIndex: 'paymentTime',
            key: '6',
            render: (item) => {
                return(
                    <span>{moment(item).format('HH:mm DD.MM.YYYY')}</span>
                )
            }
        },
    ];




    return(
        <div>
            <Table
                bordered
                dataSource={props.data.content}
                columns={columns}
                pagination={false}
                rowKey="id"
            />

        </div>

    )
}