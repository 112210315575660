import React from "react";
import instance from "../../../../Utils/API";
import {Card, Col, PageHeader, Row, Spin} from "antd";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {withRouter} from 'react-router-dom'
import {Icon, NavBar} from "antd-mobile";
import {MobileView, BrowserView} from "react-device-detect";


class CompanyServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesOpenDataList: null,
        }

        const self = this;
        instance.get('/api/openDynamicPages/services')
            .then(function (response) {
                console.log(response.data.servicesOpenDataList)
                self.setState({servicesOpenDataList: response.data.servicesOpenDataList})
            })
    }

    render() {
        const { Meta } = Card;


        if (this.state.servicesOpenDataList === null){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return(
                <div>
                    <MobileView>
                        <NavBar
                            mode="dark"
                            icon={<Icon type="left" />}
                            onLeftClick={() => {this.props.history.push('/menu')}}
                        >
                            Услуги
                        </NavBar>
                    </MobileView>
                    <BrowserView>
                        <h5 className="card-title my-3" style={{fontSize: '40px', fontWeight: 'bold', margin: 20}}>Услуги</h5>
                    </BrowserView>
                    <div style={{padding: 13}}>
                        <Row gutter={24} justify="center">
                            {
                                this.state.servicesOpenDataList.map((item) => {
                                    return(
                                        <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{marginBottom: 10}} onClick={() => this.props.history.push(`/company/services/${item.id}`)}>
                                            <Card
                                                hoverable
                                                style={{ width: '100%', height: '100%' }}
                                                cover={<img alt={item.title} src={item.imagePath} style={{height: '100%'}}/>}
                                            >
                                                <Meta title={item.title} description={item.preview} />
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </div>
            )
        }

    }
}

export default withRouter(CompanyServices)