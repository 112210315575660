import React from "react";
import {withRouter} from 'react-router-dom';
import {PageHeader, Row, Spin} from "antd";
import instance from "../../../../../Utils/API";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


class CompanyServicesId extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            content: null
        }

        const self = this;
        instance.get(`/api/openDynamicPages/services/${props.match.params.id}`)
            .then(function (response) {
                self.setState({content: response.data.serviceOpenData})
            })
    }

    render() {
        if (this.state.content === null){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return(
                <div>
                    <PageHeader
                        title={this.state.content.title}
                        onBack={() => {this.props.history.push('/company/services')}}
                    />
                    <div style={{padding: 0}}>
                        {ReactHtmlParser(this.state.content.content)}
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(CompanyServicesId)