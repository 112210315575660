import React from 'react';
import Header from "./Header/Header";
import {MainMenu} from "./Menu/MainMenu";
import {MainBody} from "./MainBody/MainBody";
import {Button, Layout, Menu, Row, Drawer} from "antd";
import { BookOutlined, CloudSyncOutlined, ContactsOutlined, ReconciliationOutlined, ReadOutlined, FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons';
import './MainPage.css'
import {MainFooter} from "./Footer/MainFooter";
import { withRouter } from 'react-router-dom';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import {MenuFoldOutlined} from "@ant-design/icons";

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleDrawer: false
        }
    }

    render() {
        const { Header, Footer, Content } = Layout;

        return(
            <Layout>
                <BrowserView style={{height: '100%'}}>
                    <Header style={{height: '72px'}}>
                        <div className="logo"  onClick={() => {this.props.history.push('')}} style={{cursor: 'pointer'}}/>
                        <Menu theme="dark" mode="horizontal" style={{display: 'flex', justifyContent: 'flex-end'}} defaultSelectedKeys={[`${this.props.location.pathname}`]}>
                            <Menu.Item key="/company/about" icon={<BookOutlined />} onClick={() => {this.props.history.push('/company/about')}}>
                                О КОМПАНИИ
                            </Menu.Item>
                            <Menu.Item key="/company/gas" icon={<CloudSyncOutlined />} onClick={() => {this.props.history.push('/company/gas')}}>
                                СЖИЖЕННЫЙ ГАЗ
                            </Menu.Item>
                            <Menu.Item key="/company/services" icon={<ReconciliationOutlined />} onClick={() => {this.props.history.push('/company/services')}}>
                                УСЛУГИ
                            </Menu.Item>
                            <Menu.Item key="/company/contacts" icon={<ContactsOutlined />} onClick={() => {this.props.history.push('/company/contacts')}}>
                                КОНТАКТЫ
                            </Menu.Item>
                            <Menu.Item key="/company/news" icon={<ReadOutlined />} onClick={() => {this.props.history.push('/company/news')}}>
                                НОВОСТИ
                            </Menu.Item>
                            <Menu.Item key="6" icon={<FileDoneOutlined />} onClick={() => window.location.assign('http://www.disclosure.ru/issuer/8601014059/')}>
                                ОТЧЕТНОСТЬ
                            </Menu.Item>
                            <Menu.Item key="/company/formgasification" onClick={() => {this.props.history.push('/company/formgasification')}}>
                                ГАЗИФИКАЦИЯ
                            </Menu.Item>
                            <Menu.SubMenu title={"Раскрытие информации"}>
                                <Menu.Item key="/company/secret" icon={<FileSearchOutlined />} onClick={() => {this.props.history.push('/company/secret')}}>
                                    РАСКРЫТИЕ ИНФОРМАЦИИ
                                </Menu.Item>
                                <Menu.Item key="/company/secret/archive" icon={<FileSearchOutlined />} onClick={() => {this.props.history.push('/company/secret/archive')}}>
                                    Архив
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    </Header>
                </BrowserView>

                <MobileView style={{height: '100%'}}>
                    <Header>
                        <Row justify="space-between" align="middle">
                            <div className="logo"  onClick={() => {this.props.history.push('')}} style={{cursor: 'pointer'}}/>
                            <MenuFoldOutlined
                                style={{color: '#ffffff', fontSize: '25px'}}
                                onClick={() => {this.setState({visibleDrawer: true})}}
                            />
                        </Row>

                        <Drawer
                            title="Меню"
                            placement="right"
                            closable={true}
                            onClose={() => this.setState({visibleDrawer: false})}
                            visible={this.state.visibleDrawer}
                            bodyStyle={{padding: 0}}
                        >
                            <Menu mode="inline" defaultSelectedKeys={[`${this.props.location.pathname}`]}>
                                <Menu.Item key="/company/about" icon={<BookOutlined />} onClick={() => {this.props.history.push('/company/about')}}>
                                    О КОМПАНИИ
                                </Menu.Item>
                                <Menu.Item key="/company/gas" icon={<CloudSyncOutlined />} onClick={() => {this.props.history.push('/company/gas')}}>
                                    СЖИЖЕННЫЙ ГАЗ
                                </Menu.Item>
                                <Menu.Item key="/company/services" icon={<ReconciliationOutlined />} onClick={() => {this.props.history.push('/company/services')}}>
                                    УСЛУГИ
                                </Menu.Item>
                                <Menu.Item key="/company/contacts" icon={<ContactsOutlined />} onClick={() => {this.props.history.push('/company/contacts')}}>
                                    КОНТАКТЫ
                                </Menu.Item>
                                <Menu.Item key="/company/news" icon={<ReadOutlined />} onClick={() => {this.props.history.push('/company/news')}}>
                                    НОВОСТИ
                                </Menu.Item>
                                <Menu.Item key="6" icon={<FileDoneOutlined />} onClick={() => window.location.assign('http://www.disclosure.ru/issuer/8601014059/')}>
                                    ОТЧЕТНОСТЬ
                                </Menu.Item>
                                <Menu.Item key="/company/formgasification" onClick={() => {this.props.history.push('/company/formgasification')}}>
                                    ГАЗИФИКАЦИЯ
                                </Menu.Item>
                                <Menu.SubMenu title={"Раскрытие информации"}>
                                    <Menu.Item key="/company/secret" icon={<FileSearchOutlined />} onClick={() => {this.props.history.push('/company/secret')}}>
                                        РАСКРЫТИЕ ИНФОРМАЦИИ
                                    </Menu.Item>
                                    <Menu.Item key="/company/secret/archive" icon={<FileSearchOutlined />} onClick={() => {this.props.history.push('/company/secret/archive')}}>
                                        Архив
                                    </Menu.Item>
                                </Menu.SubMenu>
                            </Menu>
                        </Drawer>
                    </Header>
                </MobileView>

                <Content>
                    <MainBody />
                </Content>

                <Footer className="MainFooter">
                    <MainFooter />
                </Footer>
            </Layout>
        )
    }

}

export default withRouter(MainPage)
