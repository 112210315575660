import React from 'react';
import {withRouter} from 'react-router-dom';
import instance from "../../../../../Utils/API";
import {notification, PageHeader, Row, Spin, Tag} from "antd";
import MDEditor from '@uiw/react-md-editor';


import moment from "moment";
import AdminDynamicEditpageEditor from "./AdminDynamicEditpageEditor";
import JobWithFiles from "../../../../../Utils/JobWithFiles";


class AdminDynamicEditpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageId: props.match.params.id,
            blockData: null,
        }

        const self = this;
        instance.get(`/api/dynamicPages/blocks/${props.match.params.id}`)
            .then(function (response) {
                self.setState({blockData: response.data.blockData})
            })
            .catch(function (error) {
                notification["error"]({
                    message: `${error.response.data.error}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }

    getContent = () => {
        const self = this;
        instance.get(`/api/dynamicPages/blocks/${this.props.match.params.id}`)
            .then(function (response) {
                self.setState({blockData: response.data.blockData})
            })
            .catch(function (error) {
                notification["error"]({
                    message: `${error.response.data.error}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }

    render() {
        if(this.state.blockData === null){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return (
                <div>
                    <PageHeader title={this.state.blockData.title}
                                onBack={() => this.props.history.push('/editpage')}
                                extra={[
                                    <span>Последнее редактирование:</span>,
                                    <Tag color="green">{moment(this.state.blockData.updateTime).format('DD.MM.YYYY')}</Tag>,
                                ]}
                    />

                    <AdminDynamicEditpageEditor content={this.state.blockData} getContent={this.getContent}/>

                    {/*<MDEditor*/}
                    {/*    value={this.state.blockData.content}*/}
                    {/*    onChange={(value) => {console.log(value)}}*/}
                    {/*/>*/}
                </div>
            );
        }

    }
}

export default withRouter(AdminDynamicEditpage)