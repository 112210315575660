import React from 'react';
import {withRouter} from 'react-router-dom';
import instance from "../../../../Utils/API";
import {
    Button,
    PageHeader,
    Row,
    Space,
    Spin,
    Table,
    Tag,
    Tooltip,
    Drawer,
    Col,
    Divider,
    Form,
    Input,
    Card,
    Skeleton, Popconfirm, notification
} from "antd";
import moment from "moment";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";
import JobWithFiles from "../../../../Utils/JobWithFiles";

class AdminNews extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            newsList: [],
            drawerVisible: false,
            title: '',
            content: '',
            imagePath: '',
        }


        const self = this;
        instance.get('/api/dynamicPages/news')
            .then(function (response) {
                self.setState({newsList: response.data.newsList})
            })
    }

    onFinish = () => {
        const self = this
        instance.post('/api/dynamicPages/news', {content: this.state.content, imagePath: this.state.imagePath, title: this.state.title})
            .then(function (response) {
                notification["success"]({
                    message: `${response.data.message}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                return(
                    self.getContent()
                )
            })
            .catch(function (error) {
                notification["error"]({
                    message: `${error.response.data.error}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }

    getContent = () => {
        const self = this;
        instance.get('/api/dynamicPages/news')
            .then(function (response) {
                self.setState({newsList: response.data.newsList})
            })
    }


    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Название',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Дата создания',
                dataIndex: 'creationTime',
                key: 'creationTime',
                render: (item) => {
                    return(
                        <Tag color="green">
                            {moment(item).format('DD.MM.YYYY')}
                        </Tag>
                    )
                }
            },
            {
                title: 'Посследнее обновление',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (item) => {
                    return(
                        <Tag color="magenta">
                            {moment(item).format('DD.MM.YYYY')}
                        </Tag>
                    )
                }
            },
            {
                title: 'Действия',
                key: '1',
                render: (item, record) => {
                    return(
                        <Space>
                            <Tooltip title="Редактирование">
                                <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => {this.props.history.push(`/news/${record.id}`)}}/>
                            </Tooltip>
                            <Tooltip title="Удаление">
                                <Popconfirm title="Вы уверены?"
                                            okText="Да"
                                            cancelText="Нет"
                                            onConfirm={() => {
                                                const self = this;
                                                instance.delete(`/api/dynamicPages/news/${record.id}`)
                                                    .then(function (response) {
                                                        notification['success']({
                                                            message: 'Новость успешно удалена',
                                                            description:
                                                                'Спасибо, что воспользовались нашим сервисом!',
                                                        });
                                                        self.getContent();
                                                    })
                                                    .catch(function (error) {
                                                        notification['error']({
                                                            message: error.response.data.error,
                                                            description:
                                                                'Спасибо, что воспользовались нашим сервисом!',
                                                        });
                                                    })
                                            }}
                                >
                                    <Button type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </Tooltip>
                        </Space>
                    )
                }
            },
        ]

        if(this.state.newsList.length === 0){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return (
                <div>
                    <PageHeader
                        title="Новости"
                        extra={[
                            <Tooltip title="Добавить новость">
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => {this.setState({drawerVisible: true})}} />
                            </Tooltip>
                        ]}
                    />
                    <Table dataSource={this.state.newsList} columns={columns} />

                    <Drawer
                        title="Добавление новости"
                        placement="right"
                        closable={false}
                        onClose={() => this.setState({drawerVisible: false})}
                        visible={this.state.drawerVisible}
                        width = {1200}
                    >
                        <Row justify="end">
                            <JobWithFiles />
                        </Row>
                        <Row gutter={12}>
                            <Col xs={24} md={12} xl={12}>
                                <Divider orientation="left" plain>
                                    Добавление
                                </Divider>
                                <Form
                                    name="basic"
                                    onFinish={this.onFinish}
                                    layout={"vertical"}

                                >
                                    <Form.Item
                                        label="Название новости"
                                        name="title"
                                        rules={[{
                                            required: true,
                                            message: 'Пожалуйста, введите название новости!',
                                        }]}
                                        style={{width: '100%'}}
                                    >
                                        <Input style={{width: '100%'}}
                                               onChange={(e) => {this.setState({title: e.target.value})}}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Изображение"
                                        name="imagePath"
                                        rules={[{
                                            required: true,
                                            message: 'Пожалуйста, загрузите изображение!',
                                        }]}
                                        style={{width: '100%'}}
                                    >
                                        <Input style={{width: '100%'}}
                                               onChange={(e) => {this.setState({imagePath: e.target.value})}}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Описание"
                                        name="content"
                                        rules={[{
                                            required: true,
                                            message: 'Пожалуйста, введите описание!',
                                        }]}
                                        style={{width: '100%'}}
                                    >
                                        <MDEditor
                                            preview = 'edit'
                                            onChange={(value) => {this.setState({content: value})}}
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Добавить
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Divider type="vertical" />
                            <Col xs={24} md={11} xl={11}>
                                <Divider orientation="left" plain>
                                    Предпросмотр
                                </Divider>

                                <div className="site-card-border-less-wrapper">
                                    <Card title={this.state.title === '' ? 'Новая новость' : this.state.title}
                                          bordered={false}
                                          style={{ width: 300, height: '100%' }}
                                          hoverable
                                          cover={this.state.imagePath === '' ? <Skeleton.Image  style={{width: 300}}/> : <img alt="example" src={`${this.state.imagePath}`} />}
                                    >
                                        <p>
                                            {this.state.content === '' ? <Skeleton /> : <MDEditor.Markdown source={this.state.content} />}
                                        </p>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Drawer>
                </div>
            );
        }
    }
}

export default withRouter(AdminNews)