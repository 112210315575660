import React, {useEffect} from 'react';
import instance from "../../../../../Utils/API";
import {useParams} from 'react-router-dom';


export const OneContract = props => {
    const params = useParams();
    useEffect(() => {
        instance.get(`/api/user/${params.id}`)
    },[])
    return(
        <div>
            123
        </div>
    )
}