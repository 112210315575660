import React from "react";
import instance from "./API";
import {Button, Card, Col, Descriptions, List, Menu, Modal, notification, PageHeader, Row, Spin, Tabs,} from "antd";
import {CloudUploadOutlined, FileOutlined, InboxOutlined} from "@ant-design/icons";
import InfiniteScroll from 'react-infinite-scroller';



export default class JobWithFiles extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            visibleModal: false,
            filesList: [],
            loadingFilesList: false,
            selectedFile: '',
            responseFile: '',
        }

        const self = this;
        instance.get('/api/file')
            .then(function (response) {
                self.setState({filesList: response.data.filesList, loadingFilesList: true,})
            })
    }


    changeValue = (e) => {
        this.setState({selectedFile: e.target.files})
    }

    toServer = () => {
        let formdata = new FormData();
        formdata.append("file", this.state.selectedFile[0]);

        const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        };
        const self = this;
        fetch("https://zero.obgaz.ru/api/file/upload", requestOptions)
            .then(response => response.text())
            .then(result => {
                const rawResult = JSON.parse(result)
                notification["success"]({
                    message: `Файл загружен успешно!`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                self.setState({responseFile: rawResult.fileMetaData})
            })
            .catch(error => {
                notification["error"]({
                    message: `${error.response.data.error}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            });
    }


    render() {
        if (this.state.loadingFilesList === false){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        }else {
            return(
                <div>
                    <Button type="primary" icon={<FileOutlined />} onClick={() => this.setState({visibleModal: true})}/>

                    <Modal
                        title="Работа с файлами"
                        centered
                        visible={this.state.visibleModal}
                        onOk={() => this.setState({visibleModal: false})}
                        onCancel={() => this.setState({visibleModal: false})}
                        width={1000}
                    >
                        <Tabs defaultActiveKey="1" centered>
                            <Tabs.TabPane
                                key="1"
                                tab={<span><FileOutlined /> Все файлы</span>}
                            >
                                <div className="site-card-border-less-wrapper">
                                    {this.state.filesList.length !== 0 &&
                                    <InfiniteScroll
                                        initialLoad={false}
                                        useWindow={false}
                                    >
                                        <List
                                            dataSource={this.state.filesList}
                                            renderItem={item => (
                                                <List.Item key={item.id}>
                                                    <List.Item.Meta
                                                        title={item.name}
                                                        description={item.link}
                                                    />
                                                    <div>{item.size} байт</div>
                                                </List.Item>
                                            )}
                                        >
                                        </List>
                                    </InfiniteScroll>
                                    }
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                key="2"
                                tab={<span><CloudUploadOutlined /> Загрузка файлов</span>}
                            >
                                <label htmlFor="file-upload" className={this.state.disableUpload ? 'custom-file-upload-uploaded' : 'custom-file-upload' }>
                                    <CloudUploadOutlined /> Выберите файл
                                </label>
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="form-control"
                                    disabled={this.state.fileUploadedToServer}
                                    style={{display: 'none'}}
                                    ref="fileUploader"
                                    onChange={this.changeValue}
                                />


                                <Button
                                    type="primary"
                                    loading={this.state.loading}
                                    disabled={this.state.disabledButton}
                                    onClick={() => {
                                        this.toServer();
                                    }}
                                >
                                    Загрузить файл
                                </Button>
                                {this.state.responseFile !== '' &&
                                <div style={{marginTop: 20}}>
                                    <Descriptions
                                        bordered
                                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                    >
                                        <Descriptions.Item label="Название файла">{this.state.responseFile.name}</Descriptions.Item>
                                        <Descriptions.Item label="Размер файла">{this.state.responseFile.size} байт</Descriptions.Item>
                                        <Descriptions.Item label="Ссылка на файл">{this.state.responseFile.link}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                                }
                            </Tabs.TabPane>
                        </Tabs>
                    </Modal>

                </div>
            )
        }
    }
}