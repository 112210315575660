import React from "react";
import instance from "../../../../Utils/API";
import {Row, Spin} from "antd";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {Icon, NavBar} from "antd-mobile";
import {MobileView} from "react-device-detect";
import {withRouter} from 'react-router-dom';


class CompanyGas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutGasPage: null,
        }

        const self = this;
        instance.get('/api/openDynamicPages/aboutGas')
            .then(function (response) {
                self.setState({aboutGasPage: response.data.aboutGasPage})
            })
    }

    render() {
        if (this.state.aboutGasPage === null){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return(
                <div>
                    <MobileView>
                        <NavBar
                            mode="dark"
                            icon={<Icon type="left" />}
                            onLeftClick={() => {this.props.history.push('/menu')}}
                        >
                            Сжиженный газ
                        </NavBar>
                    </MobileView>
                    {ReactHtmlParser(this.state.aboutGasPage.content)}
                </div>
            )
        }

    }
}

export default withRouter(CompanyGas)