import React from "react";
import {PageEditor} from "../../../../Utils/PageEditor/PageEditor";

export default class AdminEditor extends React.Component{

    render() {
        return(
            <PageEditor />
        )
    }
}