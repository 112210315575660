import React from 'react';
import {Modal, Button, Divider, Form, Input, InputNumber, Alert, notification} from 'antd';
import instance from "../../../../../../Utils/API";



class EnterEvalueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            enumeratorData: props.enumeratorData
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps){
            this.setState({visible: this.props.visibleModal, enumeratorData: this.props.enumeratorData})
        }
    }

    handleCancel = () => {
        this.setState({ visible: false });
        this.props.modalUnvisible(false)
    };

    onFinish = (values) => {
        this.setState({ loading: true });
        const self = this;
        instance.put('/api/enumerator/updateValue', {id: this.state.enumeratorData.id, value: values.Value})
            .then(function (response) {
                notification['success']({
                    message: 'Значение счётчика было успешно изменено!',
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                self.setState({ loading: false});
                setTimeout(() => {
                    self.setState({ visible: false });
                    self.props.modalUnvisible(false)
                    self.props.getUserData()
                }, 1000)
            })
            .catch(function (error) {
                self.setState({ loading: false});
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    };


    render() {
        const { visible, loading } = this.state;



        return (
            <>
                <Modal
                    visible={visible}
                    title={this.state.enumeratorData.enumeratorType === 'GVS' ? 'Горячее водоснабжение' : 'Отопление'}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        layout={"vertical"}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        fields={[
                            {
                                name: 'Value',
                                value: this.state.enumeratorData.enumeratorValue
                            }
                        ]}
                    >
                        <Alert message="Дробные значения записываются через точку" type="info" showIcon style={{marginBottom: 20}}/>
                        <Form.Item
                            label="Новое показание"
                            name="Value"
                            layout={"inline"}
                            rules={[{ required: true, message: 'Пожалуйста, введите нововое показание!' }]}
                        >
                            <InputNumber
                                formatter={value => value.replace(',','.')}
                                parser={value => value.replace(',','.')}
                                min={this.state.enumeratorData.enumeratorValue}
                                style={{width: '100%'}}
                                placeholder={this.state.enumeratorData.enumeratorValue}/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Внести
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default EnterEvalueModal;