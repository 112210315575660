import React from 'react';
import {
    Form,
    Input,
    Select,
    Checkbox,
    Button,
    DatePicker,
    Upload,
    InputNumber,
    ConfigProvider,
    Tooltip, Modal,
} from 'antd';
import {QuestionCircleOutlined, UploadOutlined} from '@ant-design/icons';
import GasificationEditableTable from "./GasificationEditableTable";
import {LocaleProvider} from "antd-mobile";
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
import UploadsFormGasification from "./UploadsFormGasification";
import axios from 'axios';

moment.locale('ru');
const { Option } = Select;
const { TextArea } = Input;



class FormGasification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: 'Физическое лицо',
            isManyPoints: false,
            onePointValue: null,
            manyPointsValue: [],
            notIssuedBefore: false,
            visible: false,
            loading: false
        };
    }

    handleUserTypeChange = value => {
        this.setState({ userType: value });
    }


    setManyPointsValue = (values) => {
        console.log(values)
      this.setState({manyPointsValue: values})
    };


    onFinish = (values) => {
        const formData = new FormData();
        const requestBody = {
            ...values,
        };

        const getFiles = (filePropName) => {
            values[filePropName]?.fileList.forEach(file => {
                formData.append(`${filePropName}_${file.uid}`, file.originFileObj ?? file)
            });
            delete requestBody[filePropName]
        }
        const fileListPropname = [
            'situationPlanList',
            'topoMapList',
            'propertyCadastralList',
            'copyOwnList',
            'representativePowersList',
            'capacityTransferList',
            'territoryPlanningList',
            'medicalFacilityList',
            'standaloneBoilerList',
            'licenseList',
        ];

        fileListPropname.forEach(propName => {
              getFiles(propName);
          }
        )

        for (const requestBodyKey in requestBody) {
            formData.append(requestBodyKey, requestBody[requestBodyKey], )
        }

        formData.set('userType', this.state.userType);
        if(this.state.isManyPoints) {
            formData.set('isManyPoints', 'true');
            formData.set('gasConsumptionPoints', JSON.stringify(this.state.manyPointsValue))
        } else {
            formData.set('gasConsumptionPoints', this.state.onePointValue)

        }

        this.setState({loading: true})

        axios.post('https://obgzservice.weldnet.ru/form-gasification', formData).then(() => {
            this.setState({visible: true})
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    setVisible = (visible) => {
        this.setState({visible })
    }


    render() {
        const formObject = {
            userType: "Тип пользователя",
            organizationName: "Полное и сокращенное (при наличии) наименование организации",
            egrnNumber: "Номер ЕГРН",
            lastName: "Фамилия",
            firstName: "Имя",
            middleName: "Отчество",
            passportNumber: "серия и номер паспорта",
            passportDate: "Дата выдачи паспорта",
            passportIssuedBy: "Кем выдан паспорт",
            phoneNumber: "Контактный телефон",
            email: "email",
            zipCode: "Почтовый индекс",
            mailingAddress: "Почтовый адрес",
            fullAddress: "Полный адрес",
            borderPlanNumber: "Номер плана межевания",
            borderPlanDate: "Дата плана межевания",
            serviceType: "Вид услуги",
            objectName: "Наименование объекта строительства",
            objectAddress: "Адрес объекта строительства",
            additionalWorks: "Дополнительные работы",
            maxHourlyGasFlow: "Максимальный часовой расход газа (подключаемого и ранее подключенного)",
            connectedEquipmentGasFlow: "Величина максимального часового расхода газа подключаемого оборудования, м3/час",
            previouslyConnectedEquipmentGasFlow: "Величина максимального часового расхода газа, ранее подключенного оборудования, м3/час",
            projectDesignTime: "Планируемый срок проектирования",
            gasConsumptionPoints: "Планируемая величина максимального часового расхода газа каждой из точек",
            gasConsumptionCharacteristic: "Характеристика потребления газа",
            technicalConditionsNumber: "Номер ранее выданных технических условий",
            technicalConditionsDate: "Дата ранее выданных технических условий",
            notIssuedBefore: "Технические устройства ранее не выдавались",
            additionalInformation: "Дополнительная информация"
        };

        const { userType, isManyPoints, onePointValue, manyPointsValue, notIssuedBefore, visible } = this.state;
        const isOrganization = userType === 'Юридическое лицо';

        const plainOptions = [
            { label: 'Подключение (технологическое присоединение) в пределах границ земельного участка', value: 'Подключение (технологическое присоединение) в пределах границ земельного участка' },
            { label: <>Проектирование сети газопотребления <Tooltip title={'Выбирается в случае, предусмотренном законодательством о градостроительной деятельности'}><QuestionCircleOutlined style={{color: '#9a9a9a'}}/></Tooltip></>, value: 'Проектирование сети газопотребления'},
            { label: 'Установка газоиспользующего оборудования', value: 'Установка газоиспользующего оборудования' },
            { label: 'Строительство/Реконструкция внутреннего газопровода объекта капитального строительства', value: 'Строительство/Реконструкция внутреннего газопровода объекта капитального строительства' },
            { label: 'Установка прибора учета газа', value: 'Установка прибора учета газа' },
            { label: 'Поставка прибора учета газа', value: 'Поставка прибора учета газа' },
            { label: 'Поставка газоиспользующего оборудования', value: 'Поставка газоиспользующего оборудования' }
        ]


        return (
            <ConfigProvider locale={ru_RU}>
                <div style={{marginTop: 20, marginBottom: 20, marginLeft: 40, marginRight: 40}}>
                    <Form layout={"vertical"} onFinish={this.onFinish}>
                        <Form.Item label="Тип пользователя" name={"userType"}>
                            <Select defaultValue="Физическое лицо" onChange={this.handleUserTypeChange}>
                                <Option value="Юридическое лицо">Юридическое лицо</Option>
                                <Option value="Физическое лицо">Физическое лицо</Option>
                            </Select>
                        </Form.Item>

                        {isOrganization && <>
                            <Form.Item rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}
                                       label="Полное и сокращенное (при наличии) наименование организации"
                                       name="organizationName">
                                <Input placeholder="Введите название организации"/>
                            </Form.Item>
                            <Form.Item name={"egrnNumber"} label="Номер ЕГРН"
                                       tooltip={'Для юридических лиц и индивидуальных предпринимателей'}
                                       rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                                <Input placeholder="Введите номер ЕГРН"/>
                            </Form.Item>
                        </>}

                        {!isOrganization && <>
                            <Form.Item label="Фамилия" name={"lastName"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                                <Input placeholder="Введите вашу фамилию"/>
                            </Form.Item>
                            <Form.Item label="Имя" name={"firstName"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                                <Input placeholder="Введите ваше имя"/>
                            </Form.Item>
                            <Form.Item label="Отчество" name={"middleName"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                                <Input placeholder="Введите ваше отчество"/>
                            </Form.Item>
                            <Form.Item label="Серия и номер паспорта" name={"passportNumber"} tooltip={'Для физических лиц'} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                                <Input placeholder="Введите серию и номер паспорта"/>
                            </Form.Item>
                            <Form.Item label="Дата выдачи паспорта" name={"passportDate"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                                <DatePicker locale={ru_RU} format="DD.MM.YYYY"/>
                            </Form.Item>
                            <Form.Item label="Кем выдан паспорт" name={"passportIssuedBy"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                                <Input placeholder="Укажите, кем выдан паспорт"/>
                            </Form.Item>
                        </>}

                        <Form.Item label="Контактный телефон" name={"phoneNumber"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Input placeholder="Введите контактный телефон" type={"number"}/>
                        </Form.Item>

                        <Form.Item label="E-mail" name={"email"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Input placeholder="Введите e-mail" type={"email"}/>
                        </Form.Item>

                        <Form.Item label="Почтовый индекс" name={"zipCode"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Input placeholder="Введите почтовый индекс"/>
                        </Form.Item>

                        <Form.Item label="Почтовый адрес" name={"mailingAddress"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Input placeholder="Введите почтовый адрес"/>
                        </Form.Item>

                        <Form.Item label="Полный адрес" name={"fullAddress"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Input placeholder="Введите полный адрес"/>
                        </Form.Item>

                        <Form.Item label="Номер плана межевания" name={"borderPlanNumber"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Input placeholder="Введите номер плана межевания"/>
                        </Form.Item>
                        <Form.Item label="Дата плана межевания" name={"borderPlanDate"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <DatePicker locale={ru_RU} format="DD.MM.YYYY"/>
                        </Form.Item>

                        <Form.Item label="Выберите вид услуги" name={"serviceType"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Select placeholder="Выберите вид услуги">
                                <Option value="Подключение к сети">Подключение к сети</Option>
                                <Option value="Увеличение объема газа">Увеличение объема газа</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Наименование объекта строительства"
                                   name={"objectName"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Input placeholder="Введите наименование объекта строительства"/>
                        </Form.Item>

                        <Form.Item label="Адрес объекта строительства" name={"objectAddress"}
                                   rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Input placeholder="Введите адрес объекта строительства"/>
                        </Form.Item>

                        <Form.Item label={"Дополнительные работы"} name={"additionalWorks"} rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}>
                            <Checkbox.Group options={plainOptions}/>
                        </Form.Item>

                        <Form.Item rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}
                                   label="Максимальный часовой расход газа (подключаемого и ранее подключенного), м3/час"
                                   name={"maxHourlyGasFlow"}>
                            <InputNumber
                                placeholder="Укажите максимальный часовой расход газа (подключаемого и ранее подключенного), м3/час"
                                style={{width: '100%'}}
                            />
                        </Form.Item>

                        <Form.Item rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}
                                   label="Величина максимального часового расхода газа подключаемого оборудования, м3/час"
                                   name={"connectedEquipmentGasFlow"}>
                            <InputNumber
                                placeholder="Укажите величину максимального часового расхода газа подключаемого оборудования, м3/час"
                                style={{width: '100%'}}
                            />
                        </Form.Item>

                        <Form.Item rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]}
                                   label="Величина максимального часового расхода газа, ранее подключенного оборудования, м3/час"
                                   name={"previouslyConnectedEquipmentGasFlow"}>
                            <InputNumber
                                placeholder="Укажите величину максимального часового расхода газа, ранее подключенного, м3/час"
                                style={{width: '100%'}}
                            />
                        </Form.Item>

                        <Form.Item rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]} label="Планируемый срок проектирования"
                                   name={"projectDesignTime"}>
                            <Input placeholder="Укажите планируемый срок подключения"/>
                        </Form.Item>

                        <Form.Item label="Планируемая величина максимального часового расхода газа каждой из точек"
                                   name={"gasConsumptionPoints"}>
                            <Checkbox
                                onChange={(event) => this.setState({isManyPoints: event.target.checked})}
                                defaultChecked={isManyPoints}
                            >
                                Несколько точек подключения
                            </Checkbox>
                            <br/>
                            {!isManyPoints &&
                                <InputNumber
                                    placeholder="Укажите величину максимального часового расхода газовой точки"
                                    style={{width: '100%'}}
                                    onChange={(event) => this.setState({onePointValue: event})}
                                />
                            }

                            {isManyPoints &&
                                <GasificationEditableTable
                                    setManyPointsValue={(values) => this.setManyPointsValue(values)}/>
                            }
                        </Form.Item>


                        {isOrganization && <Form.Item rules={[{
                            required: true,
                            message: `Пожалуйста введите данные!`,
                        }]} label="Характеристика потребления газа"
                                    name={'gasConsumptionCharacteristic'}>
                            <Input
                              placeholder="Укажите характеристику потребления газа"/>
                        </Form.Item>}

                        <Form.Item
                          name={"notIssuedBefore"}
                          valuePropName={'checked'}
                        >
                            <Checkbox
                              defaultChecked={isManyPoints}
                              onChange={(e) => this.setState({notIssuedBefore: e.target.checked})}
                            >
                                Технические условия ранее не выдавались
                            </Checkbox>
                        </Form.Item>

                        {!notIssuedBefore && <><Form.Item rules={[{
                            required: true,
                            message: `Пожалуйста введите данные!`,
                        }]} label="Номер ранее выданных технических условий"
                                    name={'technicalConditionsNumber'}>
                            <Input
                              placeholder="Укажите номер ранее выданных технических условий"/>
                        </Form.Item>

                            <Form.Item rules={[ {required: true, message: `Пожалуйста введите данные!`,}, ]}
                        label="Дата ранее выданных технических условий"
                        name={'technicalConditionsDate'}>
                        <DatePicker locale={ru_RU} format="DD.MM.YYYY"/>
                    </Form.Item></>}


                        <Form.Item rules={[ { required: true, message: `Пожалуйста введите данные!`, }, ]} label="Способ связи" name={"additionalInformation"}>
                            <TextArea rows={4} placeholder="Введите способ связи"/>
                        </Form.Item>
                        <div style={{marginBottom: 16}}>Приложения: <Tooltip title={'В целях' +
                          ' заключения' +
                          ' договора подключения (технологического присоединения) объекта капитального строительства ' +
                          '(объекта сети газораспределения и (или) газопотребления) к сети газораспределения к настоящему запросу прилагаются документы, предусмотренные пунктом 16 ' +
                          'Правил подключения (технологического присоединения) газоиспользующего оборудования и объектов капитального строительства к сетям газораспределения, ' +
                          'утвержденных постановлением Правительства Российской Федерации от 13 сентября 2021 г. №1547 «Об утверждении Правил подключения (технологического присоединения) ' +
                          'газоиспользующего оборудования и объектов капитального строительства к сетям газораспределения и о признании утратившим силу некоторых актов Правительства ' +
                          'Российской Федерации.»'}><QuestionCircleOutlined style={{color: '#9a9a9a'}}/></Tooltip></div>
                        <UploadsFormGasification userType={userType} />

                        <Form.Item
                          label={'Согласен на обработку персональных данных'}
                          name={'accept'}
                          valuePropName={'checked'}
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 8  }}
                          rules={[ { required: true, message: `Подтвердите согласие на обработку персональных данных`}]}>
                            <Checkbox/>
                        </Form.Item>
                        <Form.Item
                          name={'submit'}
                          valuePropName={'checked'}
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 8  }}
                          label={'Подтверждаю правильность заполнения данных'}
                          rules={[{ required: true, message: `Подтвердите правильность заполнения данных`, }]}>
                            <Checkbox/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Отправить</Button>
                        </Form.Item>
                    </Form>
                    <Modal title={'Оповещение'}
                           visible={visible}
                           onOk={() => this.setVisible(false)}
                           onCancel={() => this.setVisible(false)}
                           footer={[
                               <Button key="ok" type="primary" onClick={() => this.setVisible(false)}>
                                   OK
                               </Button>,
                           ]}>
                        Заявка была успешно отправлена
                    </Modal>
                </div>

            </ConfigProvider>
        );
    }
}

export default FormGasification;
