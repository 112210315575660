import React from 'react';
import {
    Button,
    Table,
    Drawer,
    Form,
    Input,
    InputNumber,
    Pagination,
    Tag,
    notification,
    Col,
    Card,
    Row,
    Statistic, Empty, Modal, Tooltip
} from 'antd';
import {useHistory} from 'react-router-dom';
import { EditOutlined, FieldTimeOutlined } from '@ant-design/icons';
import moment from "moment";
import instance from "../../../../../Utils/API";
import EnterEvalueModal from "../../UserProfile/Enumerators/EnterEvalueModal/EnterEvalueModal";


export const ContractsTable = props => {
    const history = useHistory();
    const [visible, setVisible] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [userData, setUserData] = React.useState('');
    const [rememberFullName, setRememberFullName] = React.useState('');
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: '1',
        },
        {
            title: 'Номер договора',
            dataIndex: 'contractNumber',
            key: '2',
        },
        {
            title: 'Ф.И.О.',
            dataIndex: 'fullName',
            key: '3',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: '4',
            render: (item) => {
                if(item.search('@temporary.com') === -1){
                    return(
                        <div>
                            <span>{item}</span>
                        </div>
                    )
                }else{
                    return(
                        <Tag color="geekblue">Почта отсутсвует</Tag>
                    )
                }
            }
        },
        {
            title: 'Баланс',
            dataIndex: 'userBalance',
            key: '5',
            render: (item) => {
                if(item.balance > 0 || item.balance !== null){
                    return(
                        <Tag color="red">{item.balance}</Tag>
                    )
                }else if(item.balance <= 0 || item.balance !== null){
                    return(
                        <Tag color="green">{item.balance}</Tag>
                    )
                }else if(item.balance === null){
                    return (
                        <Tag color="cyan">Баланс отсутсвует</Tag>
                    )
                }
            }
        },
        {
            title: 'Последний платеж',
            dataIndex: 'userBalance',
            key: '6',
            render: (item) => {
                if(item.lastTimeUpdated !== null){
                    return(
                        <div>
                            {moment(item.lastTimeUpdated).format('HH:mm DD.MM.YYYY')}
                        </div>
                    )
                }else{
                    return(
                        <div>
                            <Tag color="red">Платежи отсутсвуют</Tag>
                        </div>
                    )
                }

            }
        },
        {
            title: 'Статус',
            dataIndex: 'isAccountNew',
            key: '7',
            render: (item) => {
                if(item === false){
                    return(
                        <Tag color="green">Зарегистрирован</Tag>
                    )
                }else{
                    return(
                        <Tag color="red">Не зарегистрирован</Tag>
                    )
                }
            }
        },
        {
            title: 'Действия',
            key: '8',
            render: (item, record) => {
                return(
                    <div style={{display: 'flex', alignItem: 'center'}}>
                        <Tooltip title="Редактирование пользователя">
                            <Button type="primary" style={{marginRight: 5}}
                                    shape="circle" icon={<EditOutlined />}
                                    onClick={(e) => {
                                        setVisible(!visible);
                                        setUserData(record)
                                    }}
                            />
                        </Tooltip>

                        <Tooltip title="Сброс пользователя">
                            <Button type="primary" danger style={{marginRight: 5}}
                                    shape="circle" icon={<FieldTimeOutlined />}

                                    onClick={(e) => {
                                        setModalVisible(!modalVisible)
                                        setUserData(record);
                                    }}
                            />
                        </Tooltip>
                    </div>
                )
            }

        },
    ];


    const onFinish = (values) => {
        instance.put('/api/user',
            {address: values.address,
                apartmentNumber: values.apartmentNumber,
                contract: values.contract,
                email: values.email,
                fullName: values.fullName,
                userId: userData.id
            })
            .then(function (response) {
                notification['success']({
                    message: response.data.message,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                props.getUsers();
                setVisible(false)
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    };


    return(
        <div>
            <Table
                bordered
                dataSource={props.data.content}
                columns={columns}
                pagination={false}
                rowKey="id"
                expandable={{
                    expandedRowRender: (record) => {
                        if(record.userEnumeratorList.length !== 0){
                            return(
                                <Row gutter={16}>
                                    {
                                        record.userEnumeratorList.map((item) => {
                                            return(
                                                <Col xs={24} md={12} xl={12} style={{marginTop: 10, marginBottom: 10}}>
                                                    <Card title={item.enumeratorType === 'GVS' ? `Горячее Водоснабжение ${item.enumeratorMark} ${item.enumeratorModel}` : `Отопление ${item.enumeratorMark} ${item.enumeratorModel}`}
                                                          bordered
                                                          hoverable
                                                    >
                                                        <Row gutter={24}>
                                                            <Col xs={24} md={12} xl={12}>
                                                                <Statistic title="Дата внесения" value={moment(item.lastTimeUpdated).format('HH:mm DD.MM.YYYY')} suffix={'г.'}/>
                                                            </Col>
                                                            <Col xs={24} md={12} xl={12}>
                                                                <Statistic title="Последние показания" value={item.enumeratorValue} suffix={item.enumeratorType === 'GVS' ? `м3` : `Гкал`}/>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            )
                        } else{
                            return(
                                <Empty description="Счетчики отсутствуют"/>
                            )
                        }
                    },
                }}
            />

            <Modal title="Сброс пользователя"
                   visible={modalVisible}
                   onCancel={() => {
                       setModalVisible(false);
                   }}
                   footer={[
                       <Button key="submit" type="primary" danger onClick={(e) => {
                           instance.get(`/api/user/resetUserData/${userData.id}?fullName=${rememberFullName.fullName}`)
                               .then(function (response) {
                                   notification['success']({
                                       message: response.data.message,
                                       description:
                                           'Спасибо, что воспользовались нашим сервисом!',
                                   });
                                   props.getUsers();
                               })
                               .catch(function (error) {
                                   notification['error']({
                                       message: error.response.data.error,
                                       description:
                                           'Спасибо, что воспользовались нашим сервисом!',
                                   });
                               })
                       }}
                       >
                           Сбросить
                       </Button>,
                   ]}
            >
                <h3>Пожалуйста, введите новое имя владельца аккаунта:</h3>
                <Form
                    name="ResetUser"
                    onValuesChange={(value) => setRememberFullName(value)}
                    layout={"vertical"}
                >
                    <Form.Item
                        label="Ф.И.О."
                        name="fullName"
                        rules={[{
                            required: true,
                            message: 'Пожалуйста введите Ф.И.О.!'
                        }]}
                        style={{width: '100%'}}
                    >
                        <Input style={{width: '100%'}} />
                    </Form.Item>
                </Form>
            </Modal>


            <Drawer
                title={`Редактирование пользователя ${userData.fullName}`}
                placement="right"
                closable={false}
                onClose={() => setVisible(false)}
                visible={visible}
                width={500}
            >
                <div>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        layout={"vertical"}
                        fields={[
                            {
                                name: ["contract"],
                                value: userData.contractNumber,
                            },
                            {
                                name: ["fullName"],
                                value: userData.fullName,
                            },
                            {
                                name: ["email"],
                                value: userData.email,
                            },
                            {
                                name: ["address"],
                                value: userData.address,
                            },
                            {
                                name: ["apartmentNumber"],
                                value: userData.apartmentNumber,
                            },

                        ]}
                    >
                        <Form.Item
                            label="Номер договора"
                            name="contract"
                            rules={[{
                                type: 'number',
                                message: 'Пожалуйста введите номер договора!'
                            }]}
                            style={{width: '100%'}}
                        >
                            <InputNumber style={{width: '100%'}} defaultValue={userData.contractNumber}/>
                        </Form.Item>
                        <Form.Item
                            label="Ф.И.О."
                            name="fullName"
                            rules={[{
                                message: 'Пожалуйста введите Ф.И.О.!'
                            }]}
                            style={{width: '100%'}}
                        >
                            <Input style={{width: '100%'}} defaultValue={userData.fullName}/>
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{
                                message: 'Пожалуйста введите электронную почту!'
                            }]}
                            style={{width: '100%'}}
                        >
                            <Input style={{width: '100%'}} defaultValue={userData.email} value={`${userData.email}`}/>
                        </Form.Item>

                        <Form.Item
                            label="Адрес"
                            name="address"
                            rules={[{
                                message: 'Пожалуйста введите адрес!'
                            }]}
                            style={{width: '100%'}}
                        >
                            <Input style={{width: '100%'}} defaultValue={userData.address} value={`${userData.address}`}/>
                        </Form.Item>

                        <Form.Item
                            label="Номер квартиры"
                            name="apartmentNumber"
                            value={123}
                            rules={[{
                                message: 'Пожалуйста введите номер квартиры!'
                            }]}
                            style={{width: '100%'}}
                        >
                            <Input style={{width: '100%'}} defaultValue={userData.apartmentNumber} value={userData.apartmentNumber}/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Редактировать
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Drawer>
        </div>

    )
}