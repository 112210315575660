import React from 'react';
import {Button, Card, Col, Form, Input, notification, Row} from "antd";
import {useAuthUser} from 'react-auth-kit'
import {useHistory} from 'react-router-dom'

export const LoggedIn = props => {
    const auth = useAuthUser()
    const history = useHistory();


    return(
        <Card
            title={`Добро пожаловать ${auth().username}`}
            className="AuthComponent"
        >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Button type="primary" onClick={() => history.push('/profile')}>
                    {auth().role[0] === 'user' &&
                        <span>Личный кабинет</span>
                    }
                    {auth().role[0] === 'admin' &&
                        <span>Административная панель</span>
                    }
                    {auth().role[0] === 'moderator' &&
                        <span>Административная панель</span>
                    }
                </Button>
            </div>
        </Card>
    )
}
