import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from 'react-auth-kit'
import App from "./Components/App/App";

class Index extends React.Component {

  render() {
    return(
        <AuthProvider
            authType = {'cookie'}
            authName={'_auth'}
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === "http:"}
            refreshToken
        >
            <App />
        </AuthProvider>
    )
  }

}

ReactDOM.render(<Index />, document.getElementById('root'));
