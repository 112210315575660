import React from 'react';
import {Alert, Button, Card, Divider, Form, InputNumber, notification, Spin} from "antd";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import {NavBar} from "antd-mobile";
import instance from "../../../Utils/API";
import {withAuthUser, withSignOut} from 'react-auth-kit'
import moment from "moment";
import { withRouter } from 'react-router-dom';


class UserPayment extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            userData: '',
            loading: true,
        }
        const self = this;
        instance.get(`/api/user/additionalData/${props.authState.jti}`)
            .then(function (response) {
                self.setState({userData: response.data.userAdditionalData, loading: false})
            })
        // .catch(function (error) {
        //     console.log(error)
        //     if(error.response.data !== undefined){
        //         notification['error']({
        //             message: error.response.data.error,
        //             description:
        //                 'Спасибо, что воспользовались нашим сервисом!',
        //         });
        //     }
        // })

        if(props.location.pathname === "/pay/success" && props.location.search !== ''){
            const params = new URLSearchParams(props.location.search);
            const orderId = params.get('orderId');
            instance.post(`/api/payment/commitSuccessfulPay?bankPaymentId=${orderId}`)
                .then(function (response) {
                    notification['success']({
                        message: 'Оплата прошла успешно!',
                        description:
                            'Спасибо, что воспользовались нашим сервисом!',
                    });
                    setTimeout(() => {
                        props.history.push('/profile')
                    }, 1000)
                })
                .catch(function (error) {
                    notification['error']({
                        message: error.response.data.error,
                        description:
                            'Спасибо, что воспользовались нашим сервисом!',
                    });
                    setTimeout(() => {
                        props.history.push('/profile')
                    }, 1000)
                })
        }

    }


    onFinish = (value) => {
        const self = this;
        instance.post('/api/payment/pay', {userId: this.props.authState.jti, amount: value.Value})
            .then(function (response) {
                document.location.href = response.data.sberbankResponse.formUrl
            })
    }


    render() {
        if(this.state.loading === true){
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Spin tip="Загружаем данные..."/>
                </div>
            )
        }if(this.state.loading === false){
            return(
                <div>
                    <BrowserView>
                        <Divider orientation="left" plain>
                            <h1>Оплата</h1>
                        </Divider>

                        <div style={{display: 'flex', marginBottom: 20,}}>
                            <Alert message={`Ваш баланс: ${this.state.userData.balance.balance} ₽`}
                                   description={`Последний платеж: ${moment(this.state.userData.balance.lastTimeUpdated).format('DD.MM.YYYY')}`}
                                   type={this.state.userData.balance.balance > 0 ? 'error' : 'info'} />

                        </div>
                        <Card title="Оплата услуг" bordered={true} style={{ width: '100%'}}>
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                layout={"vertical"}
                                onFinish={this.onFinish}
                                fields={[
                                    {
                                        name: 'Value',
                                        value: this.state.userData.balance.balance
                                    }
                                ]}
                            >
                                <Alert message="Дробные значения записываются через точку" type="info" showIcon style={{marginBottom: 20}}/>
                                <Form.Item
                                    label="Введите сумму:"
                                    name="Value"
                                    layout={"inline"}
                                    rules={[{ required: true, message: 'Пожалуйста, введите нововое показание!' }]}
                                >
                                    <InputNumber
                                        formatter={value => value.replace(',','.')}
                                        parser={value => value.replace(',','.')}
                                        min={1}
                                        style={{width: '100%'}}
                                        placeholder={this.state.userData.balance.balance}
                                    />
                                </Form.Item>

                                <Form.Item style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{display: 'flex', justifyContent: 'flex-end'}}
                                    >
                                        Оплатить
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>

                    </BrowserView>


                    <MobileView>
                        <NavBar
                            mode="dark"
                            rightContent={[
                                <span>
                        Баланс: {this.state.userData.balance.balance} ₽
                    </span>
                            ]}
                        >
                            Оплата
                        </NavBar>

                        <Card title="Оплата услуг" bordered={true} style={{ width: '100%'}}>
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                layout={"vertical"}
                                onFinish={this.onFinish}
                                fields={[
                                    {
                                        name: 'Value',
                                        value: this.state.userData.balance.balance
                                    }
                                ]}
                            >
                                <Alert message="Дробные значения записываются через точку" type="info" showIcon style={{marginBottom: 20}}/>
                                <Form.Item
                                    label="Введите сумму:"
                                    name="Value"
                                    layout={"inline"}
                                    rules={[{ required: true, message: 'Пожалуйста, введите нововое показание!' }]}
                                >
                                    <InputNumber
                                        formatter={value => value.replace(',','.')}
                                        parser={value => value.replace(',','.')}
                                        min={1}
                                        style={{width: '100%'}}
                                        placeholder={this.state.userData.balance.balance}
                                    />
                                </Form.Item>

                                <Form.Item style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{display: 'flex', justifyContent: 'flex-end'}}
                                    >
                                        Оплатить
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </MobileView>
                </div>
            )
        }
    }
}

export default withRouter(withSignOut(withAuthUser(UserPayment)))