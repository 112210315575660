import React from "react";
import instance from "../../../../Utils/API";
import {Row, Spin} from "antd";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import {NavBar, Icon} from "antd-mobile";
import {withRouter} from 'react-router-dom';


class CompanyAbout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutCompany: null,
        }

        const self = this;
        instance.get('/api/openDynamicPages/aboutCompany')
            .then(function (response) {
                self.setState({aboutCompany: response.data.aboutCompanyPage})
            })
    }

    render() {
        if (this.state.aboutCompany === null){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return(
                <div>
                    <MobileView>
                        <NavBar
                            mode="dark"
                            icon={<Icon type="left" />}
                            onLeftClick={() => {this.props.history.push('/menu')}}
                        >
                            О компании
                        </NavBar>
                    </MobileView>
                    {ReactHtmlParser(this.state.aboutCompany.content)}
                </div>
            )
        }

    }
}

export default withRouter(CompanyAbout)