import React from 'react';
import {Button, Drawer, Form, Input, InputNumber, notification, PageHeader, Pagination, Row, Spin, Tooltip} from "antd";
import instance from "../../../../Utils/API";
import {TablePayments} from "./PaymentsTable/TablePayments";
import {CloudDownloadOutlined, FileExcelOutlined} from "@ant-design/icons";

export default class AdminPayments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            visibleDrawer: false,
        }
        const self = this;
        instance.get('/api/payment/?page=0&size=10')
            .then(function (response) {
                self.setState({data: response.data.paymentDataPage})
            })
    }

    getPayments = () => {
        const self = this;
        instance.get(`/api/payment/?page=0&size=10`)
            .then(function (response) {
                self.setState({data: response.data.paymentDataPage})
            })
    }

    onChangePagination = (page) => {
        const self = this;
        instance.get(`/api/payment/?page=${page-1}&size=10`)
            .then(function (response) {
                self.setState({data: response.data.paymentDataPage})
            })
    }


    render() {
        const { Search } = Input;

        if(this.state.data === ''){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else{
            return (
                <div>
                    <PageHeader
                        className="site-page-header"
                        title="Список платежей"
                        extra={[
                            <Search
                                placeholder="поиск"
                                onSearch={(value) => {
                                    const self = this;
                                    instance.get(`/api/payment/?contract=${value}`)
                                        .then(function (response) {
                                            notification['success']({
                                                message: 'Поиск выполнен успешно!',
                                                description:
                                                    'Спасибо, что воспользовались нашим сервисом!',
                                            });
                                            self.setState({data: response.data.paymentDataPage})
                                        })
                                        .catch(function (error) {
                                            notification['error']({
                                                message: error.response.data.error,
                                                description:
                                                    'Спасибо, что воспользовались нашим сервисом!',
                                            });
                                        })
                                }}
                                enterButton style={{ width: 200 }}
                            />,
                            <Tooltip title="Выгрузка данных в Эксель">
                                <Button key="1" type="primary" icon={<CloudDownloadOutlined />} onClick={() => {
                                    var requestOptions = {
                                        method: 'GET',
                                        redirect: 'follow',
                                        headers: {
                                            'Authorization': localStorage.getItem('token')
                                        }
                                    };

                                    fetch("https://zero.obgaz.ru/api/payment/downloadExcelData", requestOptions)
                                        .then(response => response.blob())
                                        .then(blob => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.style.display = 'none';
                                            a.href = url;
                                            a.download = 'Платежи.xlsx';
                                            document.body.appendChild(a);
                                            a.click();
                                            window.URL.revokeObjectURL(url);
                                        })
                                        .catch(error => console.log('error', error));

                                }}>
                                    <FileExcelOutlined />
                                </Button>
                            </Tooltip>,
                        ]}
                    />
                    <TablePayments data={this.state.data}/>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Pagination total={this.state.data.totalElements} pageSize={this.state.data.pageSize} showSizeChanger={false}  onChange={this.onChangePagination} className="ant-table-pagination ant-table-pagination-left"/>
                    </div>

                </div>
            );
        }
    }
}