import React from 'react';
import {withRouter} from 'react-router-dom';
import instance from "../../../../../Utils/API";
import {notification, PageHeader, Row, Spin, Tag} from "antd";

import moment from "moment";
import AdminEditServicesEditor from "./AdminEditServicesEditor/AdminEditServicesEditor";


class AdminEditServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageId: props.match.params.id,
            serviceData: null,
        }

        const self = this;
        instance.get(`/api/dynamicPages/services/${props.match.params.id}`)
            .then(function (response) {
                console.log(response.data)
                self.setState({serviceData: response.data.serviceData})
            })
            .catch(function (error) {
                notification["error"]({
                    message: `${error.response.data.error}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }

    getContent = () => {
        const self = this;
        instance.get(`/api/dynamicPages/services/${this.props.match.params.id}`)
            .then(function (response) {
                self.setState({serviceData: response.data.serviceData})
            })
            .catch(function (error) {
                notification["error"]({
                    message: `${error.response.data.error}`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
            })
    }

    render() {
        if(this.state.serviceData === null){
            return (
                <Row align="middle" justify="center">
                    <Spin tip="Загружаем данные..." />
                </Row>
            )
        } else {
            return (
                <div>
                    <PageHeader title={this.state.serviceData.title}
                                onBack={() => this.props.history.push('/services')}
                                extra={[
                                    <span>Последнее редактирование:</span>,
                                    <Tag color="green">{moment(this.state.serviceData.updateTime).format('DD.MM.YYYY')}</Tag>
                                ]}
                    />

                    <AdminEditServicesEditor content={this.state.serviceData} getContent={this.getContent}/>

                    {/*<MDEditor*/}
                    {/*    value={this.state.serviceData.content}*/}
                    {/*    onChange={(value) => {console.log(value)}}*/}
                    {/*/>*/}
                </div>
            );
        }

    }
}

export default withRouter(AdminEditServices)