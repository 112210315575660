import React from "react";
import {PrivateRoute} from 'react-auth-kit'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {WorkSpace, Empty, UserLayout, PageLayout} from "../Layouts/Layouts";
import MainPage from "../Pages/MainPage/MainPage";
import 'antd/dist/antd.css';
import 'antd-mobile/dist/antd-mobile.css';
import UserProfile from "../Pages/Profile/UserProfile/UserProfile";
import {withSignIn} from 'react-auth-kit'
import AdminContracts from "../Pages/Profile/AdminContracts/AdminContracts";
import {OneContract} from "../Pages/Profile/AdminContracts/OneContract/OneContract";
import AdminEdtor from "../Pages/Profile/AdminEditor/AdminEdtor";
import AdminPayments from "../Pages/Profile/AdminPayments/AdminPayments";
import AdminEnumerators from "../Pages/Profile/AdminEnumerators/AdminEnumerators";
import UserPayment from "../Pages/UserPayment/UserPayment";
import AdminEditPage from "../Pages/Profile/AdminEditPage/AdminEditPage";
import AdminDynamicEditpage from "../Pages/Profile/AdminEditPage/AdminDynamicEditpage/AdminDynamicEditpage";
import AdminNews from "../Pages/Profile/AdminNews/AdminNews";
import AdminEditNews from "../Pages/Profile/AdminNews/AdminEditNews/AdminEditNews";
import AdminServices from "../Pages/Profile/AdminServices/AdminServices";
import AdminEditServices from "../Pages/Profile/AdminServices/AdminEditServices/AdminEditServices";
import CompanyAbout from "../Pages/CompanyPages/About/About";
import CompanyGas from "../Pages/CompanyPages/Gas/Gas";
import CompanyContacts from "../Pages/CompanyPages/Contacts/Contacts";
import CompanySecret from "../Pages/CompanyPages/Secret/Secret";
import CompanyServices from "../Pages/CompanyPages/Services/Services";
import CompanyServicesId from "../Pages/CompanyPages/Services/ServicesId/ServicesId";
import CompanyNews from "../Pages/CompanyPages/News/News";
import {MobileMenu} from "../Pages/Profile/UserProfile/MobileMenu/MobileMenu";
import {withIsAuthenticated, withSignOut} from 'react-auth-kit';
import instance from "../../Utils/API";
import {Spin} from "antd";
import CompanySecretArchive from "../Pages/CompanyPages/Secret/Archive";
import FormGasification from "../Pages/FormGasification/FormGasification";



class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleLayout: Empty,
            loading: false
        }
        if(localStorage.getItem('token')){
            this.checkAuth();
        }


    }

    checkAuth = () => {
        const self = this;
        instance.get('/api/openDynamicPages/aboutCompany')
            .then(function (response) {
                self.setState({loading: false})
            })
            .catch(function (error) {
                self.setState({loading: false})
                const token = localStorage.getItem('token')
                self.props.signOut();
                localStorage.removeItem('token')
                window.location.reload();
                instance.post('/api/auth/logout', {token: token})
            })
    }

    render() {
        if(this.state.loading){
            return (
                <div className="example">
                    <Spin
                        tip="Проверяем авторизацию"
                    />
                </div>
            )
        } else {
            return(
                <BrowserRouter>
                    <Switch>
                        <SmallWrapper excat path='/company/formgasification' component={FormGasification} layout={this.props.isAuth ? WorkSpace : PageLayout} />
                        <SmallWrapper excat path='/company/secret/archive' component={CompanySecretArchive} layout={this.props.isAuth ? WorkSpace : PageLayout} />
                        <SmallWrapper excat path='/company/about' component={CompanyAbout} layout={this.props.isAuth ? WorkSpace : PageLayout} />
                        <SmallWrapper excat path='/company/gas' component={CompanyGas} layout={this.props.isAuth ? WorkSpace : PageLayout} />
                        <SmallWrapper excat path='/company/services/:id' component={CompanyServicesId} layout={this.props.isAuth ? WorkSpace : PageLayout} />
                        <SmallWrapper excat path='/company/services' component={CompanyServices} layout={this.props.isAuth ? WorkSpace : PageLayout} />
                        <SmallWrapper excat path='/company/news' component={CompanyNews} layout={this.props.isAuth ? WorkSpace : PageLayout} />
                        <SmallWrapper excat path='/company/contacts' component={CompanyContacts} layout={this.props.isAuth ? WorkSpace : PageLayout} />
                        <SmallWrapper excat path='/company/secret' component={CompanySecret} layout={this.props.isAuth ? WorkSpace : PageLayout} />

                        <RouteWrapper excat path='/contracts/:id' component={OneContract} layout={WorkSpace} />
                        <RouteWrapper excat path='/payments' component={AdminPayments} layout={WorkSpace} />
                        <RouteWrapper excat path='/enumerators' component={AdminEnumerators} layout={WorkSpace} />
                        <RouteWrapper excat path='/contracts' component={AdminContracts} layout={WorkSpace} />
                        <RouteWrapper excat path='/editpage/:id' component={AdminDynamicEditpage} layout={WorkSpace} />
                        <RouteWrapper excat path='/editpage' component={AdminEditPage} layout={WorkSpace} />
                        <RouteWrapper excat path='/news/:id' component={AdminEditNews} layout={WorkSpace} />
                        <RouteWrapper excat path='/news' component={AdminNews} layout={WorkSpace} />
                        <RouteWrapper excat path='/services/:id' component={AdminEditServices} layout={WorkSpace} />
                        <RouteWrapper excat path='/services' component={AdminServices} layout={WorkSpace} />



                        <RouteWrapper excat path='/profile' component={UserProfile} layout={WorkSpace} />
                        <RouteWrapper excat path='/menu' component={MobileMenu} layout={WorkSpace} />
                        <RouteWrapper excat path='/pay' component={UserPayment} layout={WorkSpace} />
                        <RouteWrapper excat path='/pay/success' component={UserPayment} layout={WorkSpace} />
                        <Route excat path='/register' component={MainPage} layout={Empty} />
                        <Route excat path='/changepassword' component={MainPage} layout={Empty} />
                        <Route excat path='/recovery' component={MainPage} layout={Empty} />
                        <Route excat path='/' component={MainPage} layout={Empty} />
                    </Switch>
                </BrowserRouter>
            )
        }
    }
}

const RouteWrapper = ({component: Component, layout: Layout, ...rest}) => {
    return (
        <PrivateRoute
            {...rest}
            loginPath={'/'}
            render={ props => {
                return(
                    <Layout {...props}><Component {...props} /></Layout>
                )

            }}
        />
    )
}

const SmallWrapper = ({component: Component, layout: Layout, ...rest}) => {
    return (
        <Route
            {...rest}
            render={ props => {
                return(
                    <Layout {...props}><Component {...props} /></Layout>
                )

            }}
        />
    )
}


export default withSignOut(withIsAuthenticated(withSignIn(App)))