import React from 'react';
import './MainBody.css'
import {Col, Row} from "antd";
import AuthComponent from "./Auth/AuthComponent";
import {useIsAuthenticated} from 'react-auth-kit';
import {LoggedIn} from "./LoggedIn/LoggedIn";
import {useLocation} from 'react-router-dom';
import RecoveryComponent from "./Recovery/RecoveryComponent";
import {RegComponent} from "./Reg/RegComponent";
import {FromEmailRecoveryComponent} from "./Recovery/FromEmailRecoveryComponent";

export const MainBody = props => {
    const isAuthenticated = useIsAuthenticated()
    let location = useLocation();

    return(
        <div className="MainBody">
            <Row size={24} style={{height: '100%', minHeight: '70vh', padding: 20}}>
                <Col xs={24} sm={4} md={6} lg={8} xl={12} style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    <h1 style={{color: 'white', fontSize: 50, lineHeight: 1,}}>Компания «Обьгаз» г. Ханты-Мансийск</h1>
                    <h3 style={{color: 'white', fontSize: 30, lineHeight: 1,}}>Наша главная задача — безопасное и бесперебойное обеспечение сжиженным газом потребителей ХМАО</h3>
                    <p style={{color: 'white', fontSize: 16}} >
                        Основными видами деятельности Компании являются: теплоснабжение; строительство инженерных сетей;
                        монтаж котельных установок и тепловых пунктов; снабжение сжиженным углеводородным газом ;
                        техническое обслуживание газопроводов и газового оборудования;
                        обслуживание внутридомового газового оборудования (ВДГО) и внутриквартирного газового оборудования (ВКГО);
                        заправка автомобилей сжиженным газом (пропан-бутан).
                    </p>
                </Col>
                <Col xs={24} sm={4} md={6} lg={8} xl={12} style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto'}}>

                    {/*Если пользователь авторизован*/}
                    {isAuthenticated() &&
                        <div>
                            <LoggedIn />
                        </div>
                    }

                    {/*Если пользователь не авторизован и находится на главной странице*/}
                    {isAuthenticated() === false && location.pathname === '/' &&
                        <AuthComponent />
                    }

                    {/*Если пользователь не авторизован и находится на странице регистрации*/}
                    {isAuthenticated() === false && location.pathname === '/register' &&
                        <RegComponent />
                    }

                    {/*Если пользователь не авторизован и находится на странице восстановления страницы*/}
                    {isAuthenticated() === false && location.pathname === '/changepassword' &&
                        <RecoveryComponent />
                    }

                    {/*Если пользователь прешел по ссылке восстановления пароля*/}
                    {isAuthenticated() === false && location.pathname === '/recovery' &&
                        <FromEmailRecoveryComponent />
                    }
                </Col>
            </Row>
        </div>
    )
}