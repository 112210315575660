import React from 'react';
import {Button, Card, Col, Form, Input, notification, Row} from "antd";
import instance from "../../../../../Utils/API";
import {Link, useHistory, useLocation} from "react-router-dom";
import {LockOutlined, MailOutlined, SnippetsOutlined} from "@ant-design/icons";

export const FromEmailRecoveryComponent = props => {

    const history = useHistory();
    const location = useLocation();

    let params = (new URL(document.location)).searchParams;
    let contract = params.get('contract');
    let token = params.get('token')

    const onFinish = (values) => {
        const data = {
            confirmPassword: values.confirmPassword,
            password: values.password,
            token: token,
        }
        instance.post('/api/auth/changePassword', data)
            .then(function (response) {
                notification['success']({
                    message: `Восстановление пароля прошло успешно!`,
                    description:
                        'Спасибо, что воспользовались нашим сервисом!',
                });
                history.push('/')
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Что-то пошло не так!',
                });
            })
    }

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 24 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 24 },
    };
    return(
        <Card
            title={`Смена пароля к аккаунту с номером договора: ${contract}`}
            className="AuthComponent"
            actions={[
                <div><Link to="/">Авторизация</Link></div>,
                <div><Link to="/register">Регистрация</Link></div>,
            ]}
        >
            <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
                >
                    <Input
                        prefix={<LockOutlined style={{color: '#a9a9a9'}} />}
                        type="password"
                        placeholder="Пароль"
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    rules={[{ required: true, message: 'Пожалуйста, подтвердите пароль!' }]}
                >
                    <Input
                        prefix={<LockOutlined style={{color: '#a9a9a9'}} />}
                        type="password"
                        placeholder="Подтвердите пароль"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        Сменить пароль
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )

}
