import React from "react";

import { Editor } from '@tinymce/tinymce-react';
import {Button, Col, Form, Input, notification, Row} from "antd";
import instance from "../../../../../../Utils/API";
import JobWithFiles from "../../../../../../Utils/JobWithFiles";



export default function AdminEditServicesEditor(props) {
    const editorRef = React.useRef(null);
    const [title, setTitle] = React.useState(props.content.title)
    const [imagePath, setImagePath] = React.useState(props.content.imagePath)
    const [preview, setPreview] = React.useState(props.content.preview)
    const [author, setAuthor] = React.useState(props.content.author)

    const saveService = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent()
            instance.put('/api/dynamicPages/services',
                {
                        id: props.content.id,
                        content: content,
                        title: title,
                        author: author,
                        imagePath: imagePath,
                        preview: preview,
                    }
                )
                .then(function (response) {
                    notification["success"]({
                        message: `${response.data.message}`,
                        description:
                            'Спасибо, что воспользовались нашим сервисом!',
                    });
                    return(
                        props.getContent()
                    )
                })
                .catch(function (error) {
                    return(
                        notification["error"]({
                            message: `${error.response.data.error}`,
                            description:
                                'Спасибо, что воспользовались нашим сервисом!',
                        })
                    )
                })
        }
    };


    return (
        <div>
            <Row justify="end">
                <JobWithFiles />
            </Row>
            <div className="site-card-border-less-wrapper">
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    layout={"vertical"}
                >
                    <Form.Item
                        label="Название"
                        name="title"
                    >
                        <Input defaultValue={title} onChange={(e) => setTitle(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Изображение"
                        name="imagePath"
                    >
                        <Input defaultValue={imagePath} onChange={(e) => setImagePath(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Краткое описание"
                        name="preview"
                    >
                        <Input defaultValue={preview} onChange={(e) => setPreview(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Автор"
                        name="author"
                    >
                        <Input defaultValue={author} onChange={(e) => setAuthor(e.target.value)}/>
                    </Form.Item>
                </Form>
            </div>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                apiKey="khcsgjtxfdpspdcyqoao18j3grwdn04n1wmk01cj6qoax5uj"
                initialValue={props.content.content}
                init={{
                    language: 'ru',
                    skin: 'oxide',
                    language_url: "/langs/ru.js",
                    content_css: false,
                    menubar: false,
                    height: 500,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount imagetools media code'
                    ],
                    toolbar: 'undo redo | fontselect fontsizeselect styleselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| removeformat | code',
                    content_style:
                        "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Lato', sans-serif; }",
                    font_formats:
                        "Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats; Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato Black=lato; Roboto=roboto;",
                }}
            />
            <Row justify="end" style={{marginTop: 20}}>
                <Button type="primary" onClick={saveService}>Сохранить</Button>
            </Row>

        </div>
    );
}