import React from 'react';
import {Button, Card, Col, Form, Input, notification, Row} from "antd";
import instance from "../../../../../Utils/API";
import jwt from 'jsonwebtoken';
import axios from "axios";
import { withSignIn } from 'react-auth-kit'
import {Link} from "react-router-dom";
import {LockOutlined, MailOutlined, SnippetsOutlined} from "@ant-design/icons";

export default class RecoveryComponent extends React.Component {


    onFinish = (values) => {
        instance.post('/api/auth/resetPassword', values)
            .then(function (response) {
                notification['success']({
                    message: `Проверьте почту ${values.email}`,
                    description:
                        'Письмо с ссылкой для восстановление пароля отправлена на почту!',
                });
            })
            .catch(function (error) {
                notification['error']({
                    message: error.response.data.error,
                    description:
                        'Проверьте правильность введенных данных!',
                });
            })
    }


    render() {
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 24 },
        };

        const tailLayout = {
            wrapperCol: { offset: 8, span: 24 },
        };
        return(
            <Card
                title="Восстановление пароля"
                className="AuthComponent"
                actions={[
                    <div><Link to="/">Авторизация</Link></div>,
                    <div><Link to="/register">Регистрация</Link></div>,
                ]}
            >
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="contract"
                        rules={[{ required: true, message: 'Пожалуйста, введите номер договора!' }]}
                    >
                        <Input
                            prefix={<SnippetsOutlined style={{color: '#a9a9a9'}} />}
                            placeholder="Номер договора"
                        />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Пожалуйста, проверьте правильность написания!',
                            },
                            {
                                required: true,
                                message: 'Пожалуйста введите почту!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined style={{color: '#a9a9a9'}} />}
                            placeholder="Электронная почта"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            Восстановить пароль
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}